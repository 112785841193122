import './CatalogView.css';
import {CatalogContent} from "../../../data/PageContent";
import React, {useEffect, useState} from "react";
import {CatalogPage, getItemsOfCategory, searchCatalog} from "../../../data/CatalogItems";
import {CatalogCard} from "./CatalogCard";
import {AllCatalogCategories, CatalogCategory, categoryDisplayValue} from "../../../data/CatalogCategory";
import {useData} from "../../../functional/Hooks";
import {getCatalogSettings} from "../../../data/CatalogSettings";
import {getMotoTypes} from "../../../data/MotoTypes";
import {CheckBoxFilterView} from "../../composites/CheckBoxFilterView";
import {doNothing, mapOrNull, self} from "../../../functional/Functions";
import ReactPaginate from "react-paginate";
import {BarLoader} from "react-spinners";
import {useCatalogQuery} from "../../../hooks/Catalog";
import {useUserToken} from "../../../hooks/User";
import {DebounceInput} from "react-debounce-input";
import {Search} from "@mui/icons-material";
import {NoItemsLayout} from "./NoItemsLayout";
import {getAccessoryTypes} from "../../../data/AccessoryTypes";
import {useCatalogFilter} from "../../../hooks/CatalogFilters";
import {wrapToArray} from "../../../functional/Arrays";
import {getPartTypes} from "../../../data/PartTypes";
import {CurrencySelector} from "../currency/CurrencySelector";
import {usePreferredCurrency} from "../../../hooks/Currency";
import {catalogTextFilter} from "../../../data/Filters";
import { useSearchParams } from 'react-router-dom';

function CatalogView(props: {
    content: CatalogContent
}) {
    const [searchParams] = useSearchParams()
    const [catalogPage, setCatalogPage] = useState<CatalogPage | null>(null)
    const [{
        category,
        searchString,
        pageNumber,
        motoType,
        accessoryType,
        partType,
    }, updateCatalogQuery] = useCatalogQuery()
    const [isLoading, setIsLoading] = useState(false)
    const catalogSettings = useData(() => getCatalogSettings)
    const allAccessoryTypes = useData(() => getAccessoryTypes)
    const allPartTypes = useData(() => getPartTypes)
    const allMotoTypes = useData(() => getMotoTypes)
    const catalogFilter = useCatalogFilter()
    const allCurrencies = mapOrNull(catalogSettings, (settings) => [settings.primaryCurrency, ...settings.secondaryCurrencies]) || []
    const [preferredCurrency, setPreferredCurrency] = usePreferredCurrency()
    const userToken = useUserToken()
    useEffect(() => {
        if (allAccessoryTypes && allMotoTypes) {
            setIsLoading(true)
            let catalogRequest: Promise<CatalogPage>
            if (searchString.length === 0) {
                catalogRequest = getItemsOfCategory(category, catalogFilter, pageNumber)
            } else {
                catalogRequest = searchCatalog(catalogTextFilter(searchString), pageNumber)
            }
            catalogRequest.then(setCatalogPage).then(() => setIsLoading(false))
        }
        // eslint-disable-next-line
    }, [searchParams, userToken, allAccessoryTypes, allMotoTypes])
    return <div className="Catalog">
        {searchString.length === 0 && <div className="CatalogCategories">
            <CheckBoxFilterView
                label="Категории"
                allValues={AllCatalogCategories}
                selectedValues={[category]}
                onAddValue={value => {
                    updateCatalogQuery({
                        pageNumber: 1,
                        category: value,
                    })
                }}
                onUpdateValues={doNothing}
                valueLabel={categoryDisplayValue}
            />
            <br/>
            {allMotoTypes && category === CatalogCategory.MOTORCYCLES && <CheckBoxFilterView
                label="Типы мотоциклов"
                allValues={allMotoTypes}
                selectedValues={wrapToArray(motoType)}
                onAddValue={value => {
                    updateCatalogQuery({
                        pageNumber: 1,
                        motoType: value,
                    })
                }}
                onUpdateValues={values => {
                    if (values.length === 0) {
                        updateCatalogQuery({
                            pageNumber: 1,
                            motoType: null,
                        })
                    }
                }}
                valueLabel={self}
            />}
            {allAccessoryTypes && category === CatalogCategory.ACCESSORIES && <CheckBoxFilterView
                label="Тип"
                allValues={allAccessoryTypes}
                selectedValues={wrapToArray(accessoryType)}
                onAddValue={value => {
                    updateCatalogQuery({
                        pageNumber: 1,
                        accessoryType: value,
                    })
                }}
                onUpdateValues={values => {
                    if (values.length === 0) {
                        updateCatalogQuery({
                            pageNumber: 1,
                            accessoryType: null,
                        })
                    }
                }}
                valueLabel={(type) => type.title}
            />}
            {allPartTypes && category === CatalogCategory.PARTS && <CheckBoxFilterView
                label="Тип"
                allValues={allPartTypes}
                selectedValues={wrapToArray(partType)}
                onAddValue={value => {
                    updateCatalogQuery({
                        pageNumber: 1,
                        partType: value,
                    })
                }}
                onUpdateValues={values => {
                    if (values.length === 0) {
                        updateCatalogQuery({
                            pageNumber: 1,
                            partType: null,
                        })
                    }
                }}
                valueLabel={(type) => type.title}
            />}
        </div>}
        <div className={`CatalogPage ${isLoading && "CatalogPageDisabled"}`}>
            <div className="CatalogPageToolbar">
                {preferredCurrency && <CurrencySelector
                    currencies={allCurrencies}
                    selectedCurrency={preferredCurrency}
                    setCurrency={index => setPreferredCurrency(allCurrencies[index].code)}
                />}
                <div className="CatalogPageSearchBar">
                    <Search color="disabled"/>
                    <DebounceInput
                        placeholder="Поиск по артикулу или названию"
                        value={searchString || ""}
                        debounceTimeout={300}
                        onChange={event => updateCatalogQuery({
                            pageNumber: 1,
                            searchString: event.target.value,
                        })}
                    />
                </div>
            </div>
            {isLoading && <BarLoader width="100%"/>}
            {(catalogPage && catalogPage.items.length > 0 && <>
                <div className="CatalogCardsContainer">
                    {catalogSettings && catalogPage.items.map((item, index) =>
                        React.createElement(CatalogCard, {
                            ...item,
                            ...catalogSettings,
                            key: `${index}/${item.category}/${item.id}`,
                        })
                    )}
                </div>
                <div className="CatalogPagination">
                    <ReactPaginate
                        forcePage={pageNumber - 1}
                        pageCount={catalogPage.pageCount}
                        pageRangeDisplayed={5}
                        activeLinkClassName="text-orange"
                        hrefAllControls={true}
                        hrefBuilder={(pageIndex) => `/catalog?category=${category}&page=${pageIndex}`}
                        breakLabel="..."
                        nextLabel="&rarr;"
                        previousLabel="&larr;"
                        onPageChange={args => {
                            window.scrollTo({top: 0})
                            updateCatalogQuery({
                                pageNumber: args.selected + 1,
                            })
                        }}
                    />
                </div>
            </>) || (!isLoading && <div className="Flex Center">
                <NoItemsLayout category={category}/>
            </div>)}
        </div>
    </div>;
}

export default CatalogView;
