import "./NavBar.css"
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {NavIcons} from "./NavIcons";
import {MenuItem} from "../../data/MenuItem";

function NavBar(props: {
    menuItems: MenuItem[],
    brandLogoImageUrl: string,
}) {
    const [isMenuShown, setIsMenuShown] = useState(false)
    const closeMenu = () => setIsMenuShown(false)
    const toggleMenu = () => setIsMenuShown(!isMenuShown)
    const isActiveStyleWrapper = (baseStyle: string) => `${baseStyle} ${isMenuShown ? "is-active" : ""}`
    const history = useLocation()
    useEffect(closeMenu, [history])
    return <div id="top">
        <nav className={isActiveStyleWrapper(`l-navbar navbar transparentNav navbar-transparent`)}>
            <div className="l-container">

                <button className={isActiveStyleWrapper("burger")} onClick={toggleMenu}>
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                </button>

                <ul className={isActiveStyleWrapper("nav")}>
                    {
                        props.menuItems.map((menuItem) =>
                            <li className="nav_item" key={menuItem.slug} onClick={closeMenu}>
                                <Link to={`/${menuItem.slug}`}>
                                    <div style={{color: menuItem.color?.colorHex || undefined}}>
                                        {menuItem.title}
                                    </div>
                                </Link>
                            </li>
                        )
                    }
                </ul>

                <div className="NavBarRest">
                    <NavIcons/>
                    <div className="brand">
                        <Link to="/" className="brand_logo logo"> <img src={props.brandLogoImageUrl} alt="brand"/>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    </div>;
}

export default NavBar;
