import "./OrderForm.css"
import {OutlinedButton} from "../../primitives/OutlinedButton";
import React, {useEffect, useState} from "react";
import {useEmailInputField, useMobileInputField, useNonEmptyInputField} from "../../inputs/InputFields";
import {nonEmptyOrNull} from "../../../functional/Strings";
import {useUserProfile} from "../../../hooks/User";
import {CheckBoxFilterView} from "../../composites/CheckBoxFilterView";
import {doNothing} from "../../../functional/Functions";
import {OrderSettings} from "../../../data/OrderSettings";
import {SubmitOrder} from "./SubmitOrder";
import {wrapToArray} from "../../../functional/Arrays";
import {DeliveryOfficeSelector} from "./DeliveryOfficeSelector";
import {DeliveryOffice} from "../../../data/DeliveryOffice";
import {ShippingMethodId} from "../../../data/ShippingMethod";
import {TextAreaInput} from "../../inputs/Inputs";
import {useInputField} from "../../inputs/InputField";

export function OrderForm(props: {
    orderSettings: OrderSettings,
    onSubmit: SubmitOrder,
}) {
    const shippingMethods = props.orderSettings.shippingMethods
    const paymentMethods = props.orderSettings.paymentMethods
    const userProfile = useUserProfile()
    const [renderNameInput, validateName] = useNonEmptyInputField("ФИО", userProfile?.name)
    const [renderEmailInput, validateEmail] = useEmailInputField(userProfile?.email)
    const [renderPhoneInput, validatePhone] = useMobileInputField(userProfile?.phone)
    const [renderCommentsInput, validateComments] = useInputField(TextAreaInput, "Комментарий к заказу", "", () => null)
    const [shippingMethod, setShippingMethod] = useState(shippingMethods[0])
    const [deliveryOffice, setDeliveryOffice] = useState<DeliveryOffice | null>(null)
    const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0])
    const onSubmit = () => {
        let [name, email, phone] = [
            validateName(),
            validateEmail(),
            validatePhone(),
        ]
        if (name !== null && email !== null && phone !== null) {
            props.onSubmit(
                shippingMethod,
                deliveryOffice,
                paymentMethod,
                {
                    name: name,
                    email: nonEmptyOrNull(email),
                    phone: phone,
                },
                validateComments() || "",
            )
        }
    }
    useEffect(() => {
        if (shippingMethod.id !== ShippingMethodId.Evropo4ta) {
            setDeliveryOffice(null)
        }
    }, [shippingMethod])
    return <div className="OrderForm article">
        <div className="l-feedback feedback">
            <h2 className="feedback_title">Контактные данные</h2>
            <div className="feedback_inputs">
                {renderNameInput()}
                {renderEmailInput()}
                {renderPhoneInput()}
            </div>
        </div>
        {shippingMethods.length > 0 && <div className="OrderFormShippingMethods">
            <CheckBoxFilterView
                label="Способы доставки"
                allValues={shippingMethods}
                selectedValues={wrapToArray(shippingMethod)}
                onAddValue={setShippingMethod}
                onUpdateValues={doNothing}
                valueLabel={method => method.title}
                extraComponent={(shippingMethod) => {
                    if (shippingMethod.id === ShippingMethodId.Evropo4ta) {
                        return <>
                            <DeliveryOfficeSelector
                                collectionName={`evropo4ta-offices`}
                                selected={deliveryOffice}
                                onChanged={setDeliveryOffice}
                            />
                            <div style={{height: "12px"}}/>
                        </>
                    } else {
                        return <></>
                    }
                }}
            />
        </div>}
        {paymentMethods.length > 0 && <div className="OrderFormPaymentMethods">
            <CheckBoxFilterView
                label="Способы оплаты"
                allValues={paymentMethods}
                selectedValues={wrapToArray(paymentMethod)}
                onAddValue={setPaymentMethod}
                onUpdateValues={doNothing}
                valueLabel={method => method.title}
            />
        </div>}
        <div className="l-feedback feedback">
            <div className="feedback_inputs feedback_text">
                {renderCommentsInput()}
            </div>
        </div>
        <div className="OrderFormCheckout">
            <OutlinedButton label="Оформить заказ" onClick={onSubmit}/>
        </div>
    </div>
}
